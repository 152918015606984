/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "assets/bootstrap/variables";
@import "~bootstrap/scss/bootstrap";

@import "~@ng-select/ng-select/themes/default.theme.css";

@import url("https://fonts.googleapis.com/css?family=Rubik:400,500");

html,
body {
  height: 100%;
  font-family: "Rubik", sans-serif;
}

.container {
  padding: 0 90px;
}

.cursor {
  cursor: pointer;
}

.list-group-item {
  &:hover {
    background-color: $light;
    // color: white;

    .text-muted {
      color: white !important;
    }
  }
}

.list-group-striped {
  li:nth-of-type(odd) {
    background: #f8f9fa;
  }
}

header {
  background-color: $primary;

  height: 80px;
  margin-top: 58px;

  @media screen and (max-width: 410px) {
    margin-top: 100px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 100px;
  }

  h2 {
    margin: 0;
    padding: 0;
  }

  h2 + div {
    width: 100%;
    @include media-breakpoint-up(sm) {
      width: auto;
    }
  }

  input {
    width: 100%;
    @include media-breakpoint-up(sm) {
      width: 500px;
    }
  }
}
/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
